import { Footer } from "./components/Footer";
import { StepHeader } from "./components/StepHeader";
import { GotoAllStepsButton } from "./components/GotoAllStepsButton";
import React from "react";
import { FullscreenModal } from "./components/FullscreenModal";

const TITLE = "Check Contents";

export const CheckContents = ({ dispatch }) => {
  return (
    <FullscreenModal>
      <StepHeader dispatch={dispatch} />

      <Footer>
        <div className="flex gap-8 flex-col p-8 pt-4">
          <div className="w-full flex flex-col">
            <h1 className="text-6xl text-[#282F44] flex flex-row items-center gap-2">
              {TITLE}
            </h1>
            <div className="flex flex-row gap-4 justify-end">
              <GotoAllStepsButton dispatch={dispatch} />
            </div>
            <p className="text-4xl font-medium text-[#464C64]">
              Open the box and do a scan of the contents. If you estimate more
              than half the box is non-conform, reject.
            </p>
          </div>

          <div className="flex flex-row w-full gap-8">
            <button
              className="rounded-lg text-red py-4 w-full m-0 border-2 border-red"
              onClick={() => {
                dispatch({ type: "reject" });
              }}
            >
              Reject
            </button>
            <button
              className="rounded-lg bg-[#173991] text-white py-4 w-full m-0"
              disabled={false}
              onClick={() => {
                dispatch({ type: "next" });
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </Footer>
    </FullscreenModal>
  );
};

CheckContents.title = TITLE;
