import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { useCurrentLocation } from "lib/utils";

export default function ImpressumFooter({ className }) {
  const { t } = useTranslation();
  // Get query params

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  if (currentLocation.locale === "de-DE") {
    return (
      <div
        className={`w-screen p-2 bg-[#e4e4e43d] flex text-xs text-black-300 justify-between flex-col md:flex-row gap-2 max-w-screen md:max-w-full ${className}`}
      >
        <p>
          <Trans
            i18nKey={"footer.legal_short"}
            components={{
              1: (
                <Link
                  to={t("urls.terms_and_conditions")}
                  className="cursor-pointer underline"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </p>
        <Link
          to={t("urls.impressum")}
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Impressum
        </Link>
      </div>
    );
  }

  return (
    <div
      className={`w-full p-2 bg-[#e4e4e43d] flex text-xs text-black-300 justify-between flex-col md:flex-row gap-2 max-w-[95%] md:max-w-full ${className}`}
    >
      <p>
        <Trans
          i18nKey={"footer.legal_short"}
          components={{
            1: (
              <Link
                to={t("urls.terms_and_conditions")}
                className="cursor-pointer underline"
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
        {/* <span id="footer_disclaimer"> *{t("footer.banner_disclaimer")}</span> */}
      </p>
    </div>
  );
}
