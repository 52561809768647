import { useState, useRef, useEffect, useContext } from "react";
import BrickGreenIcon from "assets/brickGreen.svg";
import BrickGreyIcon from "assets/brickGrey.svg";
import "material-symbols/outlined.css";
import { calculateEstimate } from "lib/calculateEstimate";
import { useTranslation } from "react-i18next";
import AuthContext from "contexts/AuthContext";
import MyTradeInsModal from "components/my-trade-ins-modal";
import QRCode from "react-qr-code";
import InputValidation from "components/input-validation";
import PayallButton from "components/payall-button";
import TangoButton from "components/tango-button";
// import LoadingSpinner from "components/loading-spinner";
import PaymentConfirmation from "components/payment-confirmation";
import GiftcardModal from "components/giftcard-modal";
const { format, parse } = require("date-fns");

const { REACT_APP_API } = process.env;

const fetchTakeback = async ({ accessToken, id, setTakeback, setLoading }) => {
  try {
    setLoading(true);

    const response = await fetch(`${REACT_APP_API}/takeback/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch takeback");
    }

    const data = await response.json();

    setTakeback(data?.[0]);
  } catch (error) {
    console.error(error);
  }

  setLoading(false);
};

const formatDate = ({ utcDateStr, formatStr }) => {
  const inputFormat = "yyyy-MM-dd HH:mm:ss";

  const parsedDate = parse(utcDateStr, inputFormat, new Date());

  const utcDate = new Date(
    Date.UTC(
      parsedDate.getFullYear(),
      parsedDate.getMonth(),
      parsedDate.getDate(),
      parsedDate.getHours(),
      parsedDate.getMinutes(),
      parsedDate.getSeconds()
    )
  );

  const localDate = new Date(utcDate);

  return format(localDate, formatStr);
};

export default function MyTradeInsCard({
  id,
  setPayallOpen,
  setReadyCount,
  payallCompleted,
  index,
}) {
  const [takeback, setTakeback] = useState();
  const { t, i18n } = useTranslation();
  const { user, authTokens } = useContext(AuthContext);
  const qrCodeValue = id;
  const [modalOpen, setModalOpen] = useState(false);
  const [giftcardModalOpen, setGiftcardModalOpen] = useState(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const openReceiptModal = () => setReceiptModalOpen(true);
  const closeReceiptModal = () => setReceiptModalOpen(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const handleOpenModal = () => {
    setMenuOpen(false);
    openModal();
  };

  const handleReceiptOpenModal = () => {
    setMenuOpen(false);
    openReceiptModal();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuOpen && !event.target.closest(".relative")) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOpen]);

  const [pdfUrl, setPdfUrl] = useState();
  const [receiptPdfUrl, setReceiptPdfUrl] = useState();
  const issuedAt = takeback?.u_lego_payment_issued_at;
  const receiptLabel = takeback?.u_seller_receipt_encoded;
  const consumerAddressCountry = takeback?.consumer?.country;
  const shippingLabel =
    takeback?.u_lego_dhl_label_b64_encoded ||
    takeback?.u_ups_shipping_label_encoded;

  const isAdmin = user?.email.includes("@lego");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTakeback({
      accessToken: authTokens.access_token,
      id,
      setTakeback,
      setLoading,
    });
  }, [authTokens.access_token, id]);

  const interval = useRef();

  useEffect(() => {
    if ((!shippingLabel || !receiptLabel) && !isAdmin) {
      clearInterval(interval.current);

      interval.current = setInterval(
        () => {
          fetchTakeback({
            accessToken: authTokens.access_token,
            id,
            setTakeback,
            setLoading,
          });
        },
        // separate the re-fetch of sibling cards by 3 seconds derived from index
        10000 + index * 1000 * 3
      );

      return () => clearInterval(interval.current);
    }
  }, [
    shippingLabel,
    receiptLabel,
    id,
    authTokens.access_token,
    isAdmin,
    index,
  ]);

  useEffect(() => {
    if (shippingLabel) {
      const base64Data = shippingLabel;
      const blob = new Blob(
        [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
        { type: "application/pdf" }
      );
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [shippingLabel]);

  useEffect(() => {
    if (receiptLabel) {
      const base64Data = receiptLabel;
      const blob = new Blob(
        [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
        { type: "application/pdf" }
      );
      const url = URL.createObjectURL(blob);
      setReceiptPdfUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [receiptLabel]);

  const currencySymbol = consumerAddressCountry === "DE" ? "€" : "$";
  const takeBackCountry = consumerAddressCountry === "DE" ? "de-DE" : "en-US";
  const unit = consumerAddressCountry === "DE" ? "kg" : "lbs";

  //temporary loading state for card
  useEffect(() => {
    if (!loading) {
      setReadyCount((prevState) => prevState + 1);
    }
  }, [loading, setReadyCount]);

  //Payall modal was just completed. This state is used to update the card without needing a page reload / re-fetch
  const justCompleted = Boolean(payallCompleted === takeback?.number);
  const returnMethod = takeback?.u_lego_return_type;

  // const snowPayallCompleted =
  //   takeback.u_lego_payall_payment_status === "Completed";
  const payallPending =
    takeback?.u_lego_payment_provider === `payall` &&
    takeback?.u_lego_payall_payment_status !== `Completed`;

  const bodyModal =
    takeback?.u_lego_return_type === "mail" ? (
      shippingLabel ? (
        <iframe
          src={pdfUrl}
          title={t("my_trade_ins.label_header")}
          className="min-w-full min-h-full"
        />
      ) : (
        <InputValidation
          errorMessage={t("trade-in.next_steps.print_warning")}
          fontSize="text-base"
          className="ml-8"
        />
      )
    ) : (
      <QRCode className="my-auto" value={qrCodeValue} size={250} />
    );

  const receiptBodyModal = receiptLabel ? (
    <iframe
      src={receiptPdfUrl}
      title={t("my_trade_ins.receipt")}
      className="min-w-full min-h-full"
    />
  ) : (
    <InputValidation
      errorMessage={t("trade-in.next_steps.print_receipt_warning")}
      fontSize="text-base"
      className="ml-8"
    />
  );

  const verifiedAt =
    takeback?.u_lego_verified_at || takeback?.u_lego_in_store_verified_at;
  const payoutMethod = takeback?.u_lego_payment_type;
  //paid at is at the same as verified time if payout method is giftcard - but we need the separation for payments to display payment ui
  const paidAt = payoutMethod === "giftcard" ? verifiedAt : issuedAt;

  const stepsMail = [
    {
      label: t("my_trade_ins.trade_in_created"),
      date: takeback?.sys_created_on,
    },
    { label: t("my_trade_ins.package_sent"), date: null },
    { label: t("my_trade_ins.arrived"), date: takeback?.u_lego_received_at },
    { label: t("my_trade_ins.verified"), date: verifiedAt, value: "verified" },
    {
      label:
        payoutMethod === "giftcard"
          ? t("my_trade_ins.giftcard_issued")
          : t("my_trade_ins.digital_cash_ready"),
      date: paidAt,
      value: "paid",
      ignoreDate: payallPending,
    },
  ];

  const stepsInStore = [
    {
      label: t("my_trade_ins.trade_in_created"),
      date: takeback?.sys_created_on,
    },
    { label: t("my_trade_ins.drop_in"), date: null },
    {
      label: t("my_trade_ins.verified"),
      date: verifiedAt,
      value: "verified",
    },
    {
      label:
        payoutMethod === "giftcard"
          ? t("my_trade_ins.giftcard_issued")
          : t("my_trade_ins.digital_cash_ready"),
      date: paidAt,
      value: "paid",
    },
  ];

  const steps = returnMethod === "mail" ? stepsMail : stepsInStore;

  const activeIndex = steps.reduce((latestIndex, step, index) => {
    if (!step.date || (step.date && step.ignoreDate)) return latestIndex;
    return index;
  }, -1);

  const originCountry = user?.country;
  const regionCode = i18n.language.slice(-2);
  const weight =
    takeback?.u_lego_verified_at || takeback?.u_lego_in_store_verified_at
      ? parseFloat(takeback?.u_lego_payment_weight).toFixed(1)
      : parseFloat(takeback?.u_lego_presort_weight).toFixed(1);
  const currentWeight = weight;
  const displayWeight =
    originCountry === "US" ? weight : weight.toString().replace(".", ",");
  const estimate = calculateEstimate(
    currentWeight,
    regionCode,
    takeback?.u_lego_incentive_ratio
  );
  const value =
    originCountry === "US"
      ? `${currencySymbol}${
          payoutMethod === "giftcard" ? estimate.gift : estimate.payment
        } `
      : `${
          payoutMethod === "giftcard"
            ? estimate.gift.toString().replace(".", ",")
            : estimate.payment.toString().replace(".", ",")
        } ${currencySymbol} `;

  const dotRefs = useRef([]);
  const [lineHeight, setLineHeight] = useState(0);

  useEffect(() => {
    if (dotRefs.current[activeIndex]) {
      const activeDotTop = dotRefs.current[activeIndex].offsetTop;
      setLineHeight(activeDotTop);
    }
  }, [activeIndex, steps.length]);

  const showPayment = true;

  //payment confirmation logic
  const ibanIsSet = Boolean(takeback?.u_lego_seller_iban) || justCompleted;

  const payallHeading = issuedAt
    ? t("my_trade_ins.payall_issued_heading")
    : t("my_trade_ins.payall_details_accepted_heading");
  const payallMessage = issuedAt
    ? t("my_trade_ins.payall_issued_message")
    : t("my_trade_ins.payall_details_accepted_message");

  return (
    <div
      className={`bg-white p-6 md:p-8 rounded-lg mx-auto mt-10 border border-gray-300 w-full relative ${!takeback?.number || isNaN(weight) ? `hidden` : ``}`}
    >
      <div className="flex">
        <div className="w-20 pr-8 hidden md:block">
          {verifiedAt ? (
            <img src={BrickGreenIcon} alt="Lego Brick Icon" />
          ) : (
            <img src={BrickGreyIcon} alt="Lego Brick Icon" />
          )}
        </div>
        <div className="flex-1 flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <div className="py-1 px-2 rounded-full bg-gray-300/25 border border-gray uppercase text-xs leading-none">
              {verifiedAt
                ? t("my_trade_ins.trade_in_verified")
                : t("my_trade_ins.trade_in_created")}
            </div>
            <div className="relative">
              <button
                onClick={toggleMenu}
                className={`material-symbols-outlined text-secondary hover:bg-gray-300 p-2 rounded-full`}
              >
                more_horiz
              </button>
              {menuOpen && (
                <div className="absolute right-0 mt-2 w-64 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-30">
                  <div
                    className="py-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <div className="px-4 py-1 flex justify-between gap-1">
                      <span className="block font-bold text-sm text-black">
                        {t("my_trade_ins.context_title")}
                      </span>
                      <div className="text-black-300 text-sm">
                        {takeback?.number}
                      </div>
                    </div>
                    <button
                      onClick={handleOpenModal}
                      className="block px-4 py-1 text-sm text-blue hover:underline w-full text-left"
                      role="menuitem"
                    >
                      {returnMethod === "mail"
                        ? t("my_trade_ins.shipping_label")
                        : t("my_trade_ins.qr_code")}
                    </button>
                    {verifiedAt && (
                      <button
                        onClick={handleReceiptOpenModal}
                        className="block px-4 py-1 text-sm text-blue hover:underline w-full text-left"
                        role="menuitem"
                      >
                        {t("my_trade_ins.receipt")}
                      </button>
                    )}
                    {takeback?.u_lego_diggecard_card_pin &&
                      takeback?.u_lego_diggecard_card_number && (
                        <button
                          onClick={() => setGiftcardModalOpen(true)}
                          className="block px-4 py-1 text-sm text-blue hover:underline w-full text-left"
                          role="menuitem"
                        >
                          {t("my_trade_ins.show_giftcard")}
                        </button>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <h2 className="text-4xl font-semibold leading-none">
            {displayWeight} {unit} {t("my_trade_ins.card_subtitle")}
          </h2>
          <div className="text-secondary">
            {value}{" "}
            {payoutMethod === "giftcard"
              ? t("my_trade_ins.card_giftcard")
              : t("my_trade_ins.card_payment")}
          </div>

          {/* mobile timeline */}
          <div className="lg:hidden relative w-full flex flex-col gap-8 my-4">
            <div
              className={`absolute left-2 top-2 ${
                activeIndex === steps.length - 1 ? "bottom-6" : "bottom-2"
              } w-1 bg-gray-300`}
            ></div>

            <div
              className="absolute left-2 w-1 bg-green"
              style={{
                top: "1rem",
                height: `${lineHeight}px`,
                transition: "height 0.3s ease",
              }}
            ></div>

            {steps.map((step, index) => (
              <div
                className="flex"
                key={index}
                ref={(el) => (dotRefs.current[index] = el)}
              >
                <div
                  className={`mt-1 w-5 h-5 rounded-full border-2 border-white z-10 ${
                    index <= activeIndex ? "bg-green" : "bg-gray-300"
                  }`}
                ></div>
                <div className={`ml-4 mt-1 text-sm`}>
                  <div
                    className={`${index === activeIndex ? "font-bold" : ""}`}
                  >
                    {step.label}
                  </div>
                  {step.date && (
                    <div className="text-tertiary">
                      {originCountry === "US"
                        ? formatDate({
                            utcDateStr: step.date,
                            formatStr: "MM/dd/yyyy",
                          })
                        : formatDate({
                            utcDateStr: step.date,
                            formatStr: "dd.MM.yyyy",
                          })}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* desktop timeline */}
          <div className="hidden lg:flex relative w-full items-start justify-between">
            <div className="absolute left-0 right-0 top-2 h-1 bg-gray-300"></div>
            <div
              className="absolute left-0 h-1 top-2 bg-green"
              style={{
                width: `${(activeIndex / (steps.length - 1)) * 100}%`,
                transition: "width 0.3s ease",
              }}
            ></div>
            {steps.map((step, index) => (
              <div
                key={index}
                className="relative flex flex-col items-center h-14"
              >
                <div
                  className={`w-5 h-5 rounded-full border-2 border-white z-10 ${
                    index <= activeIndex ? "bg-green" : "bg-gray-300"
                  }`}
                ></div>
                <div
                  className={`absolute top-6 text-sm flex flex-col  ${
                    index === 0
                      ? "text-left left-0 items-start"
                      : index === steps.length - 1
                        ? "text-right right-0 items-end"
                        : "text-center items-center"
                  }`}
                >
                  <div
                    className={`min-w-[160px] ${
                      index === activeIndex ? "font-bold" : ""
                    }`}
                  >
                    {step.label}
                  </div>
                  {step.date && (
                    <div className="text-tertiary">
                      {originCountry === "US"
                        ? formatDate({
                            utcDateStr: step.date,
                            formatStr: "MM/dd/yyyy",
                          })
                        : formatDate({
                            utcDateStr: step.date,
                            formatStr: "dd.MM.yyyy",
                          })}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* show payout options once verified */}

          {showPayment &&
            payoutMethod !== "giftcard" &&
            (steps[activeIndex]?.value === "verified" ||
              steps[activeIndex]?.value === "paid") &&
            takeBackCountry === "de-DE" &&
            !ibanIsSet &&
            !issuedAt && (
              <PayallButton
                className="mt-6 w-full"
                setModalOpen={setPayallOpen}
              />
            )}

          {showPayment &&
            payoutMethod !== "giftcard" &&
            steps[activeIndex]?.value === "paid" &&
            takeBackCountry === "en-US" &&
            takeback?.u_tango_redeem_link && (
              <TangoButton
                caseId={takeback?.number}
                u_tango_redeem_link={takeback?.u_tango_redeem_link}
                className="mt-6 w-full"
                activeStep={steps[activeIndex]?.value}
              />
            )}

          {/* show payment confirmation once paid or error message on payment error */}
          {!justCompleted &&
            takeBackCountry === "de-DE" &&
            payoutMethod !== "giftcard" &&
            (ibanIsSet || issuedAt) && (
              <PaymentConfirmation
                heading={payallHeading}
                message={payallMessage}
                // warningState={ibanIsSet && !snowPayallCompleted}
              />
            )}

          {modalOpen && (
            <MyTradeInsModal
              className="z-50"
              isOpen={modalOpen}
              onClose={closeModal}
              onConfirm={closeModal}
              print={returnMethod === "mail"}
              title={
                returnMethod === "mail"
                  ? t("my_trade_ins.label_header")
                  : t("my_trade_ins.qr_code")
              }
              body={bodyModal}
              primaryText={t("my_trade_ins.print_label")}
              secondaryText={t("my_trade_ins.close_modal")}
              pdfUrl={pdfUrl}
            />
          )}
          {receiptModalOpen && (
            <MyTradeInsModal
              className="z-50"
              isOpen={receiptModalOpen}
              onClose={closeReceiptModal}
              onConfirm={closeReceiptModal}
              print={true}
              title={t("my_trade_ins.receipt")}
              body={receiptBodyModal}
              primaryText={t("my_trade_ins.print_label")}
              secondaryText={t("my_trade_ins.close_modal")}
              pdfUrl={receiptPdfUrl}
            />
          )}
          {giftcardModalOpen && (
            <GiftcardModal
              setModalOpen={setGiftcardModalOpen}
              cardNumber={takeback?.u_lego_diggecard_card_number}
              cardPin={takeback?.u_lego_diggecard_card_pin}
              title={`${value} ${t("my_trade_ins.card_giftcard")}`}
            />
          )}
        </div>
      </div>
    </div>
  );
}
