import { useContext, useEffect, useState } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import Cookies from "js-cookie";
import {
  createSearchParams,
  useLocation,
  useOutletContext,
  useNavigate,
  Link,
} from "react-router-dom";
import { Input } from "components/input";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import AuthContext from "contexts/AuthContext";
import { isLegoEmail } from "consts/lego-admin";
import { checkPasswordCriteria, isValidEmail } from "lib/utils";
import { PasswordValidation } from "components/password-validation";
import PasswordInput from "components/password-input";
import { EmailValidation } from "../../components/email-validation";
import { Trans, useTranslation } from "react-i18next";
import InputValidation from "../../components/input-validation";
import { Address } from "../../components/address";
import { TAKEBACK_METHOD_NAME, COUNTRY_NAME } from "consts/query-params";
import { useCurrentLocation } from "lib/utils";
import SingleCheckbox from "components/checkbox";
// import Checklist from "components/checklist";

export default function SignUpPage() {
  // const [boxChecked, setBoxChecked] = useState(false);

  const { t } = useTranslation();
  //reset right side visual
  const { setSidebarVisual, emailInput, setIsLoading } = useOutletContext();
  useEffect(() => {
    setSidebarVisual("man");
    localStorage.setItem("emailInput", emailInput);
  }, [setSidebarVisual, emailInput]);

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );

  const [address, setAddress] = useState({
    addressLine1: ``,
    addressLine2: ``,
    addressCity: ``,
    addressCountry: queryParams[COUNTRY_NAME],
    addressZip: ``,
    addressState: ``,
  });

  //get current location settings
  const { location: currentLocation } = useCurrentLocation();

  //auth
  const { signupHandler, signupError } = useContext(AuthContext);

  //address states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(emailInput || "");
  const [password, setPassword] = useState("");

  //address validation alarms
  const [passwordAlarm, setPasswordAlarm] = useState(null);
  const [emailAlarm, setEmailAlarm] = useState(false);
  const [legoError, setLegoError] = useState(null);

  const [isSubscribedToMailReminders, setIsSubscribedToMailReminders] =
    useState(false);
  const [isSubscribedToSurvey, setIsSubscribedToSurvey] = useState(false);
  const [isNotInterested, setIsNotInterested] = useState(false);

  //handle mutually exclusive subscription states
  useEffect(() => {
    if (isNotInterested) {
      setIsSubscribedToMailReminders(false);
      setIsSubscribedToSurvey(false);
    }
  }, [isNotInterested]);

  const handleSurveyCheckboxChange = (checked) => {
    setIsSubscribedToSurvey(checked);
    if (checked) {
      setIsNotInterested(false);
    }
  };

  const handleMailRemindersCheckboxChange = (checked) => {
    setIsSubscribedToMailReminders(checked);
    if (checked) {
      setIsNotInterested(false);
    }
  };

  const navigate = useNavigate();

  //handle input change
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailAlarm(!isValidEmail(value));
    if (isLegoEmail(value)) {
      setLegoError(t("login.lego_alarm"));
    } else {
      setLegoError(null);
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordAlarm(!checkPasswordCriteria(value));
  };

  //handle submit
  const handleSubmit = () => {
    const isRestrictedLocationCookie = Cookies.get(`isRestrictedLocation`);

    if (isRestrictedLocationCookie) {
      alert(t(`ui.sign_up_geo`));
      return;
    }

    const params = queryParams
      ? createSearchParams(queryParams).toString()
      : "";

    const submitData = {
      ...(firstName?.trim() && { firstName: firstName.trim() }),
      ...(lastName?.trim() && { lastName: lastName.trim() }),
      ...(email?.trim() && { email: email.trim() }),
      ...(password?.trim() && { password: password.trim() }),
      ...(address?.addressLine1?.trim() && {
        addressLine1: address.addressLine1.trim(),
      }),
      ...(address?.addressLine2?.trim() && {
        addressLine2: address.addressLine2.trim(),
      }),
      ...(address?.addressCity?.trim() && {
        addressCity: address.addressCity.trim(),
      }),
      ...(address?.addressZip?.trim() && {
        addressZip: address.addressZip.trim(),
      }),
      ...(address?.addressState?.trim() && {
        addressState: address.addressState.trim(),
      }),
      ...(address?.addressCountry?.trim() && {
        addressCountry: address.addressCountry.toUpperCase().trim(),
      }),
      isSubscribedToSurvey,
      isSubscribedToReminders: isSubscribedToMailReminders,
      redirectTo: `${window.location.origin}${t(
        `urls.trade_in_sign_in`
      )}?${params}`,
    };

    localStorage.setItem("emailInput", email.trim());

    setIsLoading("Signing Up...");

    signupHandler(submitData, {
      onSuccess: () => {
        const navigateOptions = { pathname: t("urls.trade_in_verification") };
        if (queryParams) {
          navigateOptions.search = createSearchParams(queryParams).toString();
        }
        navigate(navigateOptions);
        navigate(0);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  };

  const allFieldsValid =
    firstName.trim() &&
    lastName.trim() &&
    email.trim() &&
    password.trim() &&
    address.addressLine1.trim() &&
    address.addressCity.trim() &&
    address.addressZip.trim() &&
    (address.addressCountry !== "US" || address.addressState) &&
    address.addressCountry.trim() &&
    !passwordAlarm &&
    !emailAlarm &&
    !legoError;

  return (
    <FormContainer
      submitLabel={t("ui.button_submit1")}
      onSubmit={handleSubmit}
      submitDisabled={!allFieldsValid}
      submitTracking="Trade-in signed up"
      secondaryLabel={t("ui.button_back")}
      secondaryHref={`${t("urls.trade_in_email")}`}
      secondaryParams={queryParams}
    >
      <ProgressBar step={5} />
      <h2 className="font-semibold text-6xl max-w-lg">
        {t("trade-in.sign_up.title")}
      </h2>
      <div className="w-full flex flex-col gap-x-large">
        <div>
          <Input
            label={t("ui.input_email")}
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => handleEmailChange(e)}
          />
          <InputValidation errorMessage={legoError} />
          <EmailValidation emailAlarm={emailAlarm} />
        </div>
        <div>
          <PasswordInput
            label={t("ui.input_password")}
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <PasswordValidation passwordAlarm={passwordAlarm} />
        </div>
        <div className="flex flex-col sm:grid grid-cols-2 gap-y-x-large sm:gap-x-x-large">
          <Input
            label={t("ui.input_first_name")}
            type="text"
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autocomplete="given-name"
          />
          <Input
            label={t("ui.input_last_name")}
            type="text"
            id="lastName"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            autocomplete="family-name"
          />
        </div>
        <Address
          address={address}
          setAddress={setAddress}
          currentLocation={currentLocation}
        />
        <InputValidation errorMessage={signupError?.message} />

        <div className="flex flex-col gap-x-large">
          <div>
            <p className="text-xl font-bold">
              {t("sign-up-email-preferences.title")}
            </p>
            <p className="text-base  text-secondary">
              {t("sign-up-email-preferences.subtitle")}
            </p>
          </div>
          <div>
            <p className="text-base text-secondary -mb-medium">
              {t("email-preferences.selectOne")}
            </p>
          </div>
          <p className="text-secondary text-base">
            <SingleCheckbox
              label={t("sign-up-email-preferences.marketingOption")}
              checked={isSubscribedToSurvey}
              setChecked={handleSurveyCheckboxChange}
            />
          </p>
          <p className="text-secondary text-base">
            <SingleCheckbox
              label={t("sign-up-email-preferences.updatesOption")}
              checked={isSubscribedToMailReminders}
              setChecked={handleMailRemindersCheckboxChange}
            />
          </p>
          <p className="text-secondary text-base">
            <SingleCheckbox
              label={t("sign-up-email-preferences.selectAll")}
              checked={isNotInterested}
              setChecked={setIsNotInterested}
            />
          </p>
        </div>
        <div className="text-sm text-black-300 mt-x-large">
          <Trans
            i18nKey="trade-in.sign_up.statement"
            components={{
              1: (
                <Link
                  to={t("urls.privacy_policy")}
                  className="text-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                ></Link>
              ),
            }}
          />
        </div>
      </div>
    </FormContainer>
  );
}
