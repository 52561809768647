import { Header } from "components/header";
import { useState, useEffect, Fragment } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

import BricksWEBP from "assets/bgBricks.webp";
import Bricks from "assets/bgBricks.jpg";
import DollarWEBP from "assets/bgDollar.webp";
import Dollar from "assets/bgDollar.jpg";
import ManWEBP from "assets/bgMan.webp";
import Man from "assets/bgMan.jpg";
import FaceWEBP from "assets/bgFace.webp";
import Face from "assets/bgFace.jpg";
import EuroWEBP from "assets/bgEuro.webp";
import Euro from "assets/bgEuro.jpg";
import StoreWEBP from "assets/bgStore.webp";
import Store from "assets/bgStore.jpg";
import MailInWEBP from "assets/bgMailIn.webp";
import MailIn from "assets/bgMailIn.jpg";
import LoadingSpinner from "components/loading-spinner";
import ImpressumFooter from "components/footer-impressum";

export default function TradeInForm() {
  const [sidebarVisual, setSidebarVisual] = useState(null);
  const [emailInput, setEmailInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  const [bannerClosed, setBannerClosed] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const stateQueryParam = queryParams.get("state");
  const inStoreLocations = stateQueryParam === "CO" || stateQueryParam === "GA";

  // Check if the banner is closed based on session storage
  const checkBannerStatus = () => {
    return sessionStorage.getItem("bannerClosed") === "true";
  };

  useEffect(() => {
    setBannerClosed(checkBannerStatus());
  }, []);

  useEffect(() => {
    const handleBannerStateChange = () => {
      setBannerClosed(checkBannerStatus());
    };

    window.addEventListener("bannerStateChanged", handleBannerStateChange);

    return () => {
      window.removeEventListener("bannerStateChanged", handleBannerStateChange);
    };
  }, []);

  return (
    <div className="min-h-screen">
      <Header showSupport={true} />
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : (
        <Fragment>
          <div className="flex md:overflow-hidden">
            <div
              className={`w-screen ${
                bannerClosed
                  ? "min-h-[calc(100vh-81px)] md:h-[calc(100vh-81px)]"
                  : inStoreLocations
                    ? "min-h-[calc(100vh-165px)] md:h-[calc(100vh-165px)]"
                    : "min-h-[calc(100vh-81px)] md:h-[calc(100vh-81px)]"
              } flex flex-col justify-between md:overflow-scroll`}
            >
              <Outlet
                context={{
                  setSidebarVisual,
                  emailInput,
                  setEmailInput,
                  setIsLoading,
                }}
              />
            </div>
            <div
              className={`hidden lg:block w-full max-w-[588px] ${bannerClosed ? "h-[calc(100vh-81px)]" : inStoreLocations ? "h-[calc(100vh-165px)]" : "h-[calc(100vh-81px)]"} relative`}
            >
              {sidebarVisual === "mail" ? (
                <picture className="absolute left-0 top-0 h-full w-full -z-10">
                  <source
                    type="image/webp"
                    srcSet={MailInWEBP}
                    className="h-full object-cover w-full"
                  />
                  <img
                    src={MailIn}
                    alt="A dollar sign made out of LEGO bricks"
                    className="h-full object-cover w-full"
                  />
                </picture>
              ) : sidebarVisual === "store" ? (
                <picture className="absolute left-0 top-0 h-full w-full -z-10">
                  <source
                    type="image/webp"
                    srcSet={StoreWEBP}
                    className="h-full object-cover w-full"
                  />
                  <img
                    src={Store}
                    alt="A dollar sign made out of LEGO bricks"
                    className="h-full object-cover w-full"
                  />
                </picture>
              ) : sidebarVisual === "face" ? (
                <picture className="absolute left-0 top-0 h-full w-full -z-10">
                  <source
                    type="image/webp"
                    srcSet={FaceWEBP}
                    className="h-full object-cover w-full"
                  />
                  <img
                    src={Face}
                    alt="The face of a smiling LEGO figure"
                    className="h-full object-cover w-full"
                  />
                </picture>
              ) : sidebarVisual === "dollar" ? (
                <picture className="absolute left-0 top-0 h-full w-full -z-10">
                  <source
                    type="image/webp"
                    srcSet={DollarWEBP}
                    className="h-full object-cover w-full"
                  />
                  <img
                    src={Dollar}
                    alt="A dollar sign made out of LEGO bricks"
                    className="h-full object-cover w-full"
                  />
                </picture>
              ) : sidebarVisual === "euro" ? (
                <picture className="absolute left-0 top-0 h-full w-full -z-10">
                  <source
                    type="image/webp"
                    srcSet={EuroWEBP}
                    className="h-full object-cover w-full"
                  />
                  <img
                    src={Euro}
                    alt="A euro sign made out of LEGO bricks"
                    className="h-full object-cover w-full"
                  />
                </picture>
              ) : sidebarVisual === "man" ? (
                <picture className="absolute left-0 top-0 h-full w-full -z-10">
                  <source
                    type="image/webp"
                    srcSet={ManWEBP}
                    className="h-full object-cover w-full"
                  />
                  <img
                    src={Man}
                    alt="A smiling LEGO figure holding a megaphone"
                    className="h-full object-cover w-full"
                  />
                </picture>
              ) : sidebarVisual === "bricks" ? (
                <picture className="absolute left-0 top-0 h-full w-full -z-20">
                  <source type="image/webp" srcSet={BricksWEBP} className="" />
                  <img
                    src={Bricks}
                    alt="LEGO bricks in various colors"
                    className="h-full object-cover w-full"
                  />
                </picture>
              ) : null}
            </div>
          </div>
          <ImpressumFooter className="" />
        </Fragment>
      )}
    </div>
  );
}
