import React, { useState } from "react";
import { ReactComponent as CameraSvg } from "../svgs/camera.svg";
import { TakePhoto } from "./TakePhoto";
import { ViewBucketPhoto } from "./ViewBucketPhoto";
import { createPortal } from "react-dom";

export const SortBucket = ({
  dispatch,
  heading,
  description,
  onPhotoCaptured,
  picture,
}) => {
  const [showCamera, setShowCamera] = useState(false);

  const onPhotoTaken = (picture) => {
    setShowCamera(false);
    onPhotoCaptured(picture);
  };

  const onCancel = () => {
    setShowCamera(false);
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center gap-1">
        <button
          className={`relative flex items-center justify-center rounded-lg bg-white h-[11rem] w-[11rem] p-${picture ? 0 : 16}`}
          onClick={() => {
            setShowCamera(true);
          }}
        >
          {picture ? (
            <img
              src={picture}
              alt={`Captured ${heading}`}
              className="relative rounded-lg h-full w-full"
            />
          ) : (
            <CameraSvg />
          )}
        </button>
        <p className="font-bold text-2xl">{heading}</p>
        <p className="text-lg">{description}</p>
      </div>
      {showCamera && !picture ? (
        <TakePhoto
          dispatch={dispatch}
          title={heading}
          onPhotoTaken={onPhotoTaken}
          onCancel={onCancel}
        />
      ) : null}
      {showCamera && Boolean(picture)
        ? createPortal(
            <ViewBucketPhoto
              dispatch={dispatch}
              title={heading}
              photo={picture}
              onDelete={() => {
                onPhotoCaptured("");
              }}
              onClose={() => {
                setShowCamera(false);
              }}
            />,
            document.body
          )
        : null}
    </>
  );
};
