import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useWarehouseContext } from "../useWarehouseContext";
import { BusyFullscreen } from "./BusyFullscreen";

export const RejectButton = ({
  onError,
  dispatch,
  text = "Reject",
  className = "rounded-lg text-red py-4 w-full m-0 border-2 border-red",
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { updateTakeback, clearTakeback } = useWarehouseContext();

  const onReject = async () => {
    try {
      setIsUpdating(true);

      await updateTakeback({ isRejected: true });

      clearTakeback();

      dispatch({
        type: "exit",
        props: { successMessage: "Successfully rejected package" },
      });
    } catch (e) {
      onError(e.message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <button
        className={className}
        disabled={false}
        onClick={() => {
          onReject();
        }}
      >
        {isUpdating ? <CircularProgress size={24} /> : text}
      </button>
      {isUpdating && <BusyFullscreen message="Rejecting Take Back" />}
    </>
  );
};
