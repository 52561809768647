import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "assets/icons/close.svg";
import { useCurrentLocation } from "lib/utils";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const [bannerOpen, setBannerOpen] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);
  const bannerRef = useRef(null);
  const [bannerCopy, setBannerCopy] = useState({
    title: "",
    text: "",
  });
  const { location: currentLocation } = useCurrentLocation();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();
  // const navigate = useNavigate();

  const isMyTradesPage = pathname.includes(t("urls.my_trade_ins"));
  const bannerPreference = sessionStorage.getItem("bannerClosed");

  const store_takeback = search.includes("in_store");

  useEffect(() => {
    setBannerCopy({
      title:
        " LEGO Stores to no longer accept bricks after November 17th, 2024.",
      text: "Thanks for your participation. LEGO stores will no longer accept bricks after November 17th, 2024. After this date, you will only be able to mail-in your bricks.",
    });
  }, []);

  // Clear bannerClosed from sessionStorage on page reload
  useEffect(() => {
    const handleUnload = () => {
      sessionStorage.removeItem("bannerClosed");
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  // Clear bannerClosed from sessionStorage on page reload for iPhone
  useEffect(() => {
    const isIphone = /iPhone/.test(navigator.userAgent);
    if (isIphone) {
      sessionStorage.removeItem("bannerClosed");
      const handlePageShow = (event) => {
        if (!event.persisted) {
          sessionStorage.removeItem("bannerClosed");
        }
      };
      window.addEventListener("pageshow", handlePageShow);
      return () => {
        window.removeEventListener("pageshow", handlePageShow);
      };
    }
  }, []);

  // updated banner - comment out for now until approved
  // useEffect(() => {
  //   // scroll to footer on asteriks click
  //   const scrollToFooter = () => {
  //     const footer = document.getElementById("footer_disclaimer");
  //     if (footer) {
  //       footer.scrollIntoView({ behavior: "smooth" });
  //     } else {
  //       navigate(t("urls.landing") + search, {
  //         state: { scrollToFooter: true },
  //       });
  //     }
  //   };

  //   const mailInText = {
  //     title: (
  //       <>
  //         {t("ui.banner.title").split("*")[0]}
  //         <span className="cursor-pointer" onClick={() => scrollToFooter()}>
  //           *
  //         </span>
  //         {t("ui.banner.title").split("*")[1]}
  //       </>
  //     ),
  //     text: (
  //       <>
  //         {t("ui.banner.text").split("*")[0]}
  //         <span className="cursor-pointer" onClick={() => scrollToFooter()}>
  //           *
  //         </span>
  //         {t("ui.banner.text").split("*")[1]}
  //       </>
  //     ),
  //   };

  //   const inStoreText = {
  //     title: "LEGO Brick Take Back Pilot Closing Soon!",
  //     text: (
  //       <>
  //         The in-store return option ends on November 17, 2024. After that, you
  //         can still mail in your bricks until December 12, 2024
  //         <span className="cursor-pointer" onClick={() => scrollToFooter()}>
  //           *
  //         </span>
  //         . Thank you for your participation.
  //       </>
  //     ),
  //   };

  //   // Set banner copy based on location
  //   if (
  //     currentLocation.state === "GA" ||
  //     currentLocation.state === "CO" ||
  //     store_takeback
  //   ) {
  //     setBannerCopy(inStoreText);
  //   } else {
  //     setBannerCopy(mailInText);
  //   }
  // }, [currentLocation.state, t, search, navigate, store_takeback]);

  // Show banner just for GA and CO location and according to user preference

  useEffect(() => {
    if (
      (currentLocation.state === "GA" ||
        currentLocation.state === "CO" ||
        store_takeback) &&
      (!bannerPreference || isMyTradesPage)
    ) {
      setBannerOpen(true);
    } else {
      setBannerOpen(false);
    }
  }, [currentLocation, bannerPreference, isMyTradesPage, store_takeback]);

  const closeBanner = () => {
    setBannerOpen(false);
    sessionStorage.setItem("bannerClosed", true);
    window.dispatchEvent(new Event("bannerStateChanged"));
  };

  // Set banner height to push down the main content
  useEffect(() => {
    if (bannerOpen && bannerRef.current) {
      setBannerHeight(bannerRef.current.offsetHeight);
    }
  }, [bannerOpen]);

  return (
    bannerOpen && (
      <>
        <div
          ref={bannerRef}
          className="fixed top-[71px] md:top-[87px] left-0 w-full flex flex-row justify-between items-start bg-red-300 p-large z-30"
        >
          <div className="flex flex-col gap-x-large">
            <div>
              <p className="font-bold">{bannerCopy.title}</p>
              <p className="text-secondary text-sm">{bannerCopy.text}</p>
            </div>
          </div>
          {!isMyTradesPage && (
            <div className="cursor-pointer" onClick={() => closeBanner()}>
              <img
                src={CloseIcon}
                className="w-12 h-6 fill-[#282F44]"
                alt="Close"
              />
            </div>
          )}
        </div>
        {/* Placeholder to push down the main content with fixed banner */}
        <div
          style={{ height: bannerRef.current?.offsetHeight || bannerHeight }}
          className="headerPlaceholder sticky w-full h-[65px] md:h-[74px]"
        ></div>
      </>
    )
  );
};

export default Banner;
