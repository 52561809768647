import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "assets/logo.jpg";
import AuthContext from "contexts/AuthContext";
import { ProfileHead } from "pages/svgs/profile";
import MailIcon from "assets/icons/mail.svg";

import { Trans, useTranslation } from "react-i18next";

import { cn } from "lib/utils";
import useIsMobile from "hooks/useIsMobile";
import Banner from "components/banner";

export const Header = ({
  classNames,
  children,
  mobileChildren,
  showSupport = false,
}) => {
  const { user, logoutHandler } = useContext(AuthContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);

  const location = useLocation();
  const isStoreView = location.pathname.includes("/store");

  const navigate = useNavigate();
  const navigateWithParams = (href) => {
    console.log(`${href}${location.search}`);
    navigate(`${href}${location.search}`);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    logoutHandler();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !toggleRef.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, toggleRef]);

  const { t } = useTranslation();

  const isMobile = useIsMobile();

  return (
    <>
      <Fragment>
        <div className="headerPlaceholder sticky w-full h-[71px] md:h-[87px]"></div>
        {/* placeholder above added after making header fixed - to push down page content by height of header */}
        <div
          className={cn(
            "fixed top-0 right-0 w-full bg-white flex justify-between items-center p-large border-b border-gray-300 z-40",
            classNames
          )}
        >
          <div
            className="shrink-0 flex items-center space-x-4 lg:space-x-6 cursor-pointer"
            onClick={() =>
              navigateWithParams(
                isStoreView ? t("urls.store_check_in") : t("urls.landing")
              )
            }
          >
            <img
              src={logo}
              alt="LEGO Logo"
              className="w-[40px] h-[40px] md:w-[55px] md:h-[55px]"
            />
            <h1 className="text-xl font-bold md:text-3xl md:font-semibold">
              LEGO® Brick Take Back
            </h1>
          </div>
          <div className="flex space-x-6 items-center max-w-fit">
            {children}
            {/* {user && (
            <div className="flex space-x-2">
              {isAdmin && isStoreView ? (
                <Button href="/">Seller View</Button>
              ) : (
                isAdmin && (
                  <Button href={t("urls.store_check_in")}>Store View</Button>
                )
              )}
            </div>
          )} */}
            <div className="flex space-x-4 whitespace-nowrap">
              {user ? (
                <div className="relative">
                  <div className="flex flex-row space-x-2">
                    <div
                      ref={toggleRef}
                      className="flex space-x-2 items-center cursor-pointer px-large"
                      onClick={toggleDropdown}
                    >
                      <ProfileHead />
                      <span className="hidden md:block">
                        {user.first_name} {user.last_name}
                      </span>
                    </div>
                    {isMobile && mobileChildren}
                  </div>
                  {dropdownVisible && (
                    <div
                      ref={dropdownRef}
                      className="absolute right-0 mt-2 md:mt-4 lg:max-w-[260px] px-3 py-2 max-w-[220px] bg-white border border-gray-300 rounded-lg shadow-lg"
                    >
                      <div className="flex flex-col">
                        <span className="font-bold text-xl truncate">
                          {user.first_name} {user.last_name}
                        </span>
                        <span className="text-[#757575] text-sm truncate">
                          {user.email}
                        </span>
                      </div>
                      <button
                        onClick={handleLogout}
                        className="w-full text-left pt-2 pb-1"
                      >
                        <span className=" text-blue hover:text-blue-600 hover:underline text-base">
                          Logout
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  onClick={() => navigate(t("urls.login"))}
                  className="flex space-x-2 items-center cursor-pointer px-large lg:hidden"
                >
                  <ProfileHead />
                </div>
              )}
              {showSupport && (
                <div className="flex space-x-2 whitespace-nowrap">
                  <img
                    src={MailIcon}
                    alt="Mail Icon"
                    className="hidden lg:block scale-90"
                  />
                  <a
                    href="mailto:support@legobricktakeback.com"
                    className="lg:hidden"
                  >
                    <img src={MailIcon} alt="Mail Icon" className="scale-90" />
                  </a>
                  <div className="hidden lg:block">
                    <Trans
                      i18nKey="ui.header_need_support"
                      components={{
                        1: (
                          <a
                            href="mailto:support@legobricktakeback.com"
                            className="cursor-pointer text-blue"
                          >
                            Contact Support
                          </a>
                        ),
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
      <Banner />
    </>
  );
};
