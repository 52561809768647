import { PackageLookup } from "./PackageLookup";
import { CapturePackagePhotos } from "./CapturePackagePhotos";
import { CheckContents } from "./CheckContents";
import { ControlWeightCapture } from "./ControlWeightCapture";
import { ContentWeightCapture } from "./ContentWeightCapture";
import { CaptureContentPhotos } from "./CaptureContentPhotos";
import { SortContents } from "./SortContents";
import { AssembledWeightCapture } from "./AssembledWeightCapture";
import { ConformingWeightCapture } from "./ConformingWeightCapture";
import { Summary } from "./Summary";
import { RejectContents } from "./RejectContents";
import { ProcessingIssues } from "./ProcessingIssues";
import { AllSteps } from "./AllSteps";
import { AutoEscalated } from "./AutoEscalated";

const steps = [
  PackageLookup,
  CapturePackagePhotos,
  CheckContents,
  ControlWeightCapture,
  ContentWeightCapture,
  CaptureContentPhotos,
  SortContents,
  AssembledWeightCapture,
  ConformingWeightCapture,
  Summary,
  ProcessingIssues,
];

export const stepReducer = (state, action) => {
  switch (action.type) {
    case "next": {
      const newStep = state.currentStep + 1;

      return {
        currentStep: newStep,
        StepComponent: steps[newStep],
        hasNext: newStep < steps.length - 1,
        hasPrevious: newStep > 0,
      };
    }
    case "previous": {
      const newStep = state.currentStep - 1;

      return {
        currentStep: newStep,
        StepComponent: steps[newStep],
        hasNext: newStep < steps.length - 1,
        hasPrevious: newStep > 0,
      };
    }
    case "exit": {
      return {
        currentStep: -1,
        StepComponent: null,
        hasNext: true,
        hasPrevious: false,
        props: action.props,
      };
    }
    case "clear": {
      return {
        ...state,
        props: {},
      };
    }
    case "reject": {
      return {
        ...state,
        StepComponent: RejectContents,
      };
    }
    case "cancel-reject": {
      return {
        ...state,
        StepComponent: steps[state.currentStep],
      };
    }
    case "open-all-steps": {
      return {
        ...state,
        StepComponent: AllSteps,
      };
    }
    case "close-all-steps": {
      return {
        ...state,
        StepComponent: steps[state.currentStep],
      };
    }
    case "show-escalated": {
      return {
        ...state,
        StepComponent: AutoEscalated,
      };
    }
    case "close-log-issue": {
      return {
        ...state,
        StepComponent: steps[state.currentStep],
        props: action.props,
      };
    }
    case "jump-to": {
      const { StepComponent } = action.props;
      const newStep = steps.findIndex((step) => step === StepComponent);

      return {
        ...state,
        StepComponent: steps[newStep],
        currentStep: newStep,
        hasNext: newStep < steps.length - 1,
        hasPrevious: newStep > 0,
      };
    }
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
};
