const isNumber = (maybeNumber) => {
  return typeof maybeNumber === "number";
};

const useActualWeight = (controlWeight) => (weight) => {
  return isNumber(weight) ? weight - controlWeight : undefined;
};

export const useDerivedWeights = ({
  preSortWeight,
  controlWeight,
  contentWeight,
  conformingWeight,
  assembledWeight,
  weightConverter,
}) => {
  const actualWeightCalc = useActualWeight(controlWeight);
  const actualContentWeight = actualWeightCalc(contentWeight);

  const actualConformingWeight = actualWeightCalc(conformingWeight);
  const actualAssembledWeight = actualWeightCalc(assembledWeight);

  const acceptableLegoWeight =
    isNumber(actualConformingWeight) && isNumber(actualAssembledWeight)
      ? weightConverter(actualConformingWeight + actualAssembledWeight)
      : "";

  const paymentWeight =
    isNumber(acceptableLegoWeight) && isNumber(preSortWeight)
      ? Math.max(acceptableLegoWeight, preSortWeight)
      : "";

  const nonConformingWeight =
    isNumber(actualContentWeight) &&
    isNumber(actualAssembledWeight) &&
    isNumber(actualConformingWeight)
      ? actualContentWeight - actualAssembledWeight - actualConformingWeight
      : "";

  return {
    actualContentWeight,
    actualConformingWeight,
    actualAssembledWeight,
    paymentWeight,
    nonConformingWeight,
  };
};
