import React, { useState, useMemo } from "react";

import { useWarehouseContext } from "./useWarehouseContext";
import { EditTitleButton } from "./components/EditTitleButton";
import { Footer } from "./components/Footer";
import { CircularProgress } from "@mui/material";

import { ReactComponent as WarningSvg } from "./svgs/warning.svg";
import { ReactComponent as InfoSvg } from "./svgs/info.svg";
import { ControlWeightCapture } from "./ControlWeightCapture";
import { ContentWeightCapture } from "./ContentWeightCapture";
import { AssembledWeightCapture } from "./AssembledWeightCapture";
import { ConformingWeightCapture } from "./ConformingWeightCapture";
import { CapturePackagePhotos } from "./CapturePackagePhotos";
import { CaptureContentPhotos } from "./CaptureContentPhotos";
import { SortContents } from "./SortContents";
import { StepHeader } from "./components/StepHeader";
import { BusyFullscreen } from "./components/BusyFullscreen";
import { useLocale } from "./useLocale";
import { GotoAllStepsButton } from "./components/GotoAllStepsButton";
import { FullscreenModal } from "./components/FullscreenModal";
import { clsx } from "clsx";

const TITLE = "Summary";

const WeightDisplay = ({ weight, error }) => {
  const locale = useLocale();

  const formatter = useMemo(
    () => new Intl.NumberFormat(locale, { style: "decimal" }),
    [locale]
  );

  return (
    <span className="text-6xl flex items-baseline font-bold">
      {weight !== "" ? (
        <>
          <span className={clsx({ "text-[#C12960]": error })}>
            {formatter.format(weight)}
          </span>
          <span className="text-xl ml-2">kg</span>
        </>
      ) : (
        "-"
      )}
    </span>
  );
};

const ActualWeightDisplay = ({ weight, controlWeight }) => {
  const locale = useLocale();

  const formatter = useMemo(
    () => new Intl.NumberFormat(locale, { style: "decimal" }),
    [locale]
  );
  const shouldDisplay = weight !== "" && controlWeight !== "";

  const negativeValue = weight - controlWeight < 0;

  return (
    shouldDisplay && (
      <p
        className={`${negativeValue ? "text-[#C12960]" : "text-[#8086A0]"} text-[#8086A0] text-xl flex items-baseline`}
      >
        <span className="font-bold mr-1">
          {formatter.format(weight - controlWeight)} kg
        </span>{" "}
        exc. control weight
      </p>
    )
  );
};
export const Summary = ({ dispatch }) => {
  const {
    packagePictures,
    controlWeight,
    contentWeight,
    contentPictures,
    sortedPictures,
    assembledWeight,
    conformingWeight,
    nonConformingWeight,
    updateTakeback,
    clearTakeback,
  } = useWarehouseContext();
  const [error, setError] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const hasMissingOrInvalidData =
    [
      controlWeight,
      contentWeight,
      assembledWeight,
      conformingWeight,
      nonConformingWeight,
    ].some((weight) => weight === "" || weight < 0) ||
    [packagePictures, contentPictures].some((pics) => !Boolean(pics.length)) ||
    Object.keys(sortedPictures).length === 0;

  const onFinish = async () => {
    setError("");

    if (hasMissingOrInvalidData) {
      dispatch({ type: "next" });

      return;
    }

    try {
      setIsUpdating(true);

      // if the takeback has already been verified (412 error), then ignore the error and just exit as success
      await updateTakeback({ ignore412: true });

      clearTakeback();

      dispatch({
        type: "exit",
        props: { successMessage: "Successfully processed package" },
      });
    } catch (e) {
      setError(
        "Finalising Take Back Failed. Please try again. If this issue persists, please log an issue."
      );
    } finally {
      setIsUpdating(false);
    }
  };

  const jumpTo = (StepComponent) => {
    dispatch({
      type: "jump-to",
      props: { StepComponent },
    });
  };

  return (
    <>
      <FullscreenModal>
        <StepHeader dispatch={dispatch} />
        <div className="relative w-full pb-[14rem] min-h-[calc(100vh-6rem)]">
          <div className="w-full p-8 flex flex-col gap-3">
            <div className="grid grid-cols-5 gap-4">
              <div className="flex flex-col bg-white p-4 pt-0 pb-4 rounded-lg">
                <EditTitleButton
                  title="Control Weight"
                  onClick={() => jumpTo(ControlWeightCapture)}
                  justifyBetween
                />
                <WeightDisplay weight={controlWeight} />
                {controlWeight === "" && (
                  <p className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center">
                    <WarningSvg style={{ width: "1rem" }} /> Weight not recorded
                  </p>
                )}
              </div>
              <div className="flex flex-col bg-white p-4 py-0 rounded-lg">
                <EditTitleButton
                  title="Contents Weight"
                  onClick={() => jumpTo(ContentWeightCapture)}
                  justifyBetween
                />
                <WeightDisplay weight={contentWeight} />

                {contentWeight === "" ? (
                  <p className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center">
                    <WarningSvg style={{ width: "1rem" }} /> Weight not recorded
                  </p>
                ) : (
                  <ActualWeightDisplay
                    weight={contentWeight}
                    controlWeight={controlWeight}
                  />
                )}
              </div>
              <div className="flex flex-col bg-white p-4 py-0 rounded-lg">
                <EditTitleButton
                  title="Assembled Weight"
                  onClick={() => jumpTo(AssembledWeightCapture)}
                  justifyBetween
                />
                <WeightDisplay weight={assembledWeight} />

                {assembledWeight === "" ? (
                  <p className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center">
                    <WarningSvg style={{ width: "1rem" }} /> Weight not recorded
                  </p>
                ) : (
                  <ActualWeightDisplay
                    weight={assembledWeight}
                    controlWeight={controlWeight}
                  />
                )}
              </div>
              <div className="flex flex-col bg-white p-4 py-0 rounded-lg">
                <EditTitleButton
                  title="Conforming Weight"
                  onClick={() => jumpTo(ConformingWeightCapture)}
                  justifyBetween
                />
                <WeightDisplay weight={conformingWeight} />

                {conformingWeight === "" ? (
                  <p className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center">
                    <WarningSvg style={{ width: "1rem" }} /> Weight not recorded
                  </p>
                ) : (
                  <ActualWeightDisplay
                    weight={conformingWeight}
                    controlWeight={controlWeight}
                  />
                )}
              </div>
              <div className="flex flex-col bg-white p-4 pt-2 pb-4 rounded-lg">
                <EditTitleButton
                  title="Non-Conforming Weight"
                  onClick={() => {}}
                  justifyBetween
                  canEdit={false}
                />
                <div className="mt-3">
                  <WeightDisplay
                    weight={nonConformingWeight}
                    error={nonConformingWeight < 0}
                  />
                </div>
                {nonConformingWeight < 0 && (
                  <p className="text-xl text-[#C12960] relative bottom-0 flex flex-row gap-2 items-center">
                    <InfoSvg style={{ width: "1.5rem", fill: "#C12960" }} />{" "}
                    Negative weight
                  </p>
                )}
                {nonConformingWeight === "" && (
                  <p className="text-xl relative bottom-0 flex flex-row gap-2 items-center">
                    <InfoSvg style={{ width: "1.5rem" }} /> Awaiting all weights
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col border-b-2 border-b-[#DAE3F9] pb-4">
              <EditTitleButton
                title="Package Photos"
                onClick={() => {
                  jumpTo(CapturePackagePhotos);
                }}
              />
              {packagePictures.length > 0 ? (
                <div className="grid gap-2 grid-cols-6 lg:grid-cols-12">
                  {packagePictures.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={image}
                        alt={`Captured ${index}`}
                        className="w-full h-[4rem] border rounded-lg"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center w-full">
                  <WarningSvg style={{ width: "1rem" }} /> Photos not taken
                </p>
              )}
            </div>
            <div className="flex flex-col border-b-2 border-b-[#DAE3F9] pb-4">
              <EditTitleButton
                title="Contents Photos"
                onClick={() => {
                  jumpTo(CaptureContentPhotos);
                }}
              />
              {contentPictures.length > 0 ? (
                <div className="grid gap-2 grid-cols-6 lg:grid-cols-12">
                  {contentPictures.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={image}
                        alt={`Captured ${index}`}
                        className="w-full h-[4rem] border rounded-lg"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center w-full">
                  <WarningSvg style={{ width: "1rem" }} /> Photos not taken
                </p>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <EditTitleButton
                title="Sorting Photos"
                onClick={() => {
                  jumpTo(SortContents);
                }}
              />
              {Object.keys(sortedPictures).length > 0 ? (
                <div className="grid grid-cols-6 gap-2 lg:grid-cols-12">
                  {Object.keys(sortedPictures).map((key) => (
                    <div key={key} className="relative">
                      <img
                        src={sortedPictures[key]}
                        alt={key}
                        className="w-full h-[4rem] border rounded-lg"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-xl text-[#D8A924] relative bottom-0 flex flex-row gap-2 items-center w-full">
                  <WarningSvg style={{ width: "1rem" }} /> Photos not taken
                </p>
              )}
            </div>
          </div>

          <Footer opacity="100%">
            <div className="flex gap-4 flex-col p-8">
              <div className="w-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                  <h1 className="text-6xl text-[#282F44]">Summary</h1>
                  <GotoAllStepsButton dispatch={dispatch} />
                </div>
                {error && (
                  <div
                    className="bg-[#F9DAE3] text-[#51001C] border-l-2 border-l-[#C12960] text-xl p-4 mt-4 flex flex-row gap-2 align-top mb-large w-full"
                    style={{
                      color: "red",
                    }}
                  >
                    {error}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-2 w-full gap-8">
                <button
                  className="rounded-lg font-normal border-2 border-[#DAE3F9] py-4 w-full m-0"
                  onClick={() => {
                    dispatch({ type: "previous" });
                  }}
                  disabled={isUpdating}
                >
                  Back
                </button>
                <button
                  className="rounded-lg bg-[#173991] text-white hover:bg-blue-700 font-normal py-4 w-full m-0"
                  onClick={() => {
                    onFinish();
                  }}
                  disabled={isUpdating}
                >
                  {isUpdating ? <CircularProgress size={24} /> : "Finish"}
                </button>
              </div>
            </div>
          </Footer>
        </div>
      </FullscreenModal>
      {isUpdating && <BusyFullscreen message="Finalising Take Back" />}
    </>
  );
};

Summary.title = TITLE;
