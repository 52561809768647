import React, { useCallback, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useTakebackByTrackingLazy } from "../../../api/use-takebacks";
import { useTrackingNumberValidator } from "./useTrackingNumberValidator";
import { useWarehouseContext } from "./useWarehouseContext";
import { Footer } from "./components/Footer";
import { ReactComponent as ClearSvg } from "./svgs/close.svg";
import { StepHeader } from "./components/StepHeader";
import { clsx } from "clsx";
import { format, parse } from "date-fns";
import { FullscreenModal } from "./components/FullscreenModal";

export const PackageLookup = ({ dispatch }) => {
  const { checkInPackage, startProcessingPackage, setCurrentTakeback } =
    useWarehouseContext();
  const [trackingId, setTrackingId] = useState("");
  const [error, setError] = useState("");
  const [checkedInSuccess, setCheckedInSuccess] = useState(false);
  const [checkingInPackage, setCheckingInPackage] = useState(false);
  const [processingPackage, setProcessingPackage] = useState(false);

  const trackingNumberValidator = useTrackingNumberValidator();

  const getTakeback = useTakebackByTrackingLazy();

  const trackingInputCallback = useCallback(
    (inputElement) => {
      if (inputElement || trackingId === "") {
        inputElement?.focus();
      }
    },
    [trackingId]
  );

  const onCheckInPackage = async () => {
    setCheckingInPackage(true);
    setCheckedInSuccess(false);

    const { upsTracking, dhlTracking, shippingCode } =
      trackingNumberValidator(trackingId);

    if (Boolean(upsTracking || dhlTracking || shippingCode)) {
      try {
        const [currentTakeback] =
          (await getTakeback({
            upsTracking,
            dhlTracking,
            shippingCode,
          })) ?? [];

        if (!currentTakeback) {
          setError("Invalid tracking number");
        } else {
          if (Boolean(currentTakeback.u_lego_received_at)) {
            setTrackingId("");
            setCheckedInSuccess(true);
          } else {
            await checkInPackage(currentTakeback);

            setTrackingId("");
            setCheckedInSuccess(true);
          }
        }
      } catch (err) {
        setError(err.message);
      }
    } else {
      setError("Invalid tracking number");
    }

    setCheckingInPackage(false);
  };

  const onProcessPackage = async () => {
    setProcessingPackage(true);
    setCheckedInSuccess(false);

    const { upsTracking, dhlTracking, shippingCode } =
      trackingNumberValidator(trackingId);

    if (Boolean(upsTracking || dhlTracking || shippingCode)) {
      try {
        const [currentTakeback] =
          (await getTakeback({
            upsTracking,
            dhlTracking,
            shippingCode,
          })) ?? [];

        if (!currentTakeback) {
          setError("Invalid tracking number");
        } else {
          if (Boolean(currentTakeback.u_lego_verified_at)) {
            const verifiedAtDate = parse(
              currentTakeback.u_lego_verified_at,
              "yyyy-MM-dd HH:mm:ss",
              new Date()
            );
            setError(
              `A package using this tracking code was processed on ${format(verifiedAtDate, "MM/dd/yyy 'at' HH:mm")}.`
            );
          } else {
            await startProcessingPackage(currentTakeback);

            setCurrentTakeback(currentTakeback);
            dispatch({ type: "next" });
          }
        }
      } catch (e) {
        setError(e.message);
      }
    } else {
      setError("Invalid tracking number");
    }

    setProcessingPackage(false);
  };

  const clearTrackingId = () => {
    setTrackingId("");
    setError("");
  };

  const isBusy = checkingInPackage || processingPackage;

  return (
    <FullscreenModal>
      <StepHeader
        dispatch={dispatch}
        showModal={false}
        showLogIssueIcon={false}
      />

      <Footer>
        <div className="flex gap-8 flex-col p-8">
          {checkedInSuccess && (
            <div className="border-l-4 border-l-[#00CFA8] bg-[#D5F8F1] p-4">
              Package Checked in
            </div>
          )}
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-6xl text-[#282F44]">Scan Package</h1>
            <p className="text-4xl font-medium text-[#464C64]">
              Scan the barcode on the shipping label or enter manually below.
            </p>
            <div className="w-full flex flex-row items-center justify-center mt-large">
              <input
                ref={trackingInputCallback}
                className={clsx(
                  "rounded-lg p-2 border-2 focus:outline-none focus:ring-0 w-full",
                  {
                    "border-[#C12960]": error,
                    "border-[#C6CCE8]": !error,
                    "bg-[#F9DAE3]": error,
                  }
                )}
                type="text"
                value={trackingId}
                placeholder="Tracking ID"
                disabled={isBusy}
                onChange={(e) => {
                  setError("");
                  setTrackingId(e.target.value);
                }}
              />
              <button
                className="absolute right-6 mr-10"
                onClick={() => clearTrackingId()}
              >
                <ClearSvg style={{ width: "1rem", height: "1rem" }} />
              </button>
            </div>
            <div
              className={clsx(
                "text-xl h-4 flex flex-row gap-2 align-top mb-large text-[#C12960]",
                {
                  visible: error,
                  hidden: !error,
                }
              )}
            >
              {error}
            </div>
          </div>
          <div className="flex flex-row w-full gap-8">
            <button
              className="rounded-lg disabled:cursor-not-allowed disabled:bg-[#C6CCE8] bg-[#173991] text-white hover:bg-blue-700 font-bold py-4 w-full m-0"
              disabled={isBusy}
              onClick={() => {
                onCheckInPackage();
              }}
            >
              {checkingInPackage ? (
                <CircularProgress size={24} />
              ) : (
                "Check In Package"
              )}
            </button>
            <button
              className="rounded-lg disabled:cursor-not-allowed disabled:bg-[#C6CCE8] bg-[#173991] text-white hover:bg-blue-700 font-bold py-4 w-full m-0"
              disabled={isBusy}
              onClick={() => {
                onProcessPackage();
              }}
            >
              {processingPackage ? (
                <CircularProgress size={24} />
              ) : (
                "Process Package"
              )}
            </button>
          </div>
        </div>
      </Footer>
    </FullscreenModal>
  );
};
