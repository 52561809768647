import React, { useRef, useState } from "react";
import { useWarehouseContext } from "./useWarehouseContext";
import { ReactComponent as PhotoSvg } from "./svgs/photo.svg";
import { ReactComponent as DeleteSvg } from "./svgs/delete.svg";
import { Footer } from "./components/Footer";
import { RejectButton } from "./components/RejectButton";
import { StepHeader } from "./components/StepHeader";
import { TakePhotoButton } from "./components/TakePhotoButton";
import { FullscreenModal } from "./components/FullscreenModal";
import { Webcam } from "./components/Webcam";

export const RejectContents = ({ dispatch }) => {
  const [error, setError] = useState("");

  const [showThumbnails, setShowThumbnails] = useState(false);
  const {
    rejectedContentsPictures,
    addRejectContentsPicture,
    deleteRejectContentsPicture,
  } = useWarehouseContext();
  const webcamRef = useRef(null);

  const handleCapturePackage = () => {
    const picture = webcamRef.current.getScreenshot();
    addRejectContentsPicture(picture);
  };

  return (
    <FullscreenModal>
      <Webcam ref={webcamRef} />
      <StepHeader dispatch={dispatch} />

      <Footer>
        <div className="flex gap-8 flex-col p-8 pt-4">
          <div className="w-full flex flex-col">
            {error && (
              <div
                className="bg-[#F9DAE3] text-[#51001C] border-l-2 border-l-[#C12960] text-xl p-4 w-full"
                style={{
                  color: "red",
                }}
              >
                {error}
              </div>
            )}
            <div className="flex flex-row justify-between">
              <h1 className="text-6xl text-[#282F44] flex flex-row items-center gap-2">
                Reject Take Back{" "}
                <span className="relative">
                  <PhotoSvg style={{ width: "34px" }} />
                  {rejectedContentsPictures?.length > 0 && (
                    <sup className="absolute top-0 right-[-0.25rem] rounded-full w-5 h-5 bg-red text-white text-sm text-center">
                      {rejectedContentsPictures.length}
                    </sup>
                  )}
                </span>
              </h1>

              <div className="flex flex-row gap-4">
                <button
                  className="p-6 bg-white rounded-lg border-2 border-[#DAE3F9]"
                  onClick={() => {
                    setShowThumbnails(!showThumbnails);
                  }}
                >
                  <PhotoSvg style={{ width: "24px", height: "24px" }} />
                </button>
              </div>
            </div>
            <p className="text-4xl font-medium text-[#464C64]">
              Take photos of the package including any damage
            </p>
          </div>

          {showThumbnails && (
            <div className="text-white h-full flex flex-row gap-2 align-top">
              <div className="grid gap-4 grid-cols-6 lg:grid-cols-12">
                {rejectedContentsPictures.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={image}
                      alt={`Captured ${index}`}
                      className="w-full h-auto border rounded-lg"
                    />
                    <button
                      onClick={() => deleteRejectContentsPicture(image)}
                      className="absolute top-[-1rem] right-[-1rem]"
                    >
                      <DeleteSvg
                        className="w-6 h-6 fill-red"
                        alt="Delete image"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex flex-row w-full gap-8">
            <TakePhotoButton
              dispatch={dispatch}
              onPhotoConfirmed={handleCapturePackage}
              capturePhoto={() => {
                return webcamRef.current.getScreenshot();
              }}
              title="Contents Photo"
            />
            <button
              className="rounded-lg text-red py-4 w-full m-0 border-2 border-red"
              onClick={() => {
                dispatch({ type: "cancel-reject" });
              }}
            >
              Cancel
            </button>
            <RejectButton
              className="rounded-lg bg-[#173991] text-white py-4 w-full m-0"
              text="Confirm"
              onError={(message) => {
                setError(
                  "Failed to reject Take Back. Please try again. If this issue persists, please log an issue."
                );
              }}
              dispatch={dispatch}
            />
          </div>
        </div>
      </Footer>
    </FullscreenModal>
  );
};
