const HOTMAIL_DOMAINS = [
  "outlook.com",
  "outlook.co.uk",
  "outlook.de",
  "outlook.fr",
  "outlook.es",
  "outlook.it",
  "outlook.nl",
  "outlook.se",
  "outlook.ch",
  "outlook.be",
  "outlook.ca",
  "outlook.com.br",
  "outlook.com.mx",
  "outlook.com.ar",
  "outlook.co.jp",
  "outlook.com.cn",
  "outlook.co.in",
  "outlook.co.kr",
  "outlook.com.au",
  "outlook.co.nz",
  "outlook.co.za",
  "hotmail.com",
  "hotmail.co.uk",
  "hotmail.de",
  "hotmail.fr",
  "hotmail.es",
  "hotmail.it",
  "hotmail.nl",
  "hotmail.se",
  "hotmail.ch",
  "hotmail.be",
  "hotmail.ca",
  "hotmail.com.br",
  "hotmail.com.mx",
  "hotmail.com.ar",
  "hotmail.co.jp",
  "hotmail.com.cn",
  "hotmail.co.in",
  "hotmail.co.kr",
  "hotmail.com.au",
  "hotmail.co.nz",
  "hotmail.co.za",
  "live.com",
  "live.co.uk",
  "live.de",
  "live.fr",
  "live.es",
  "live.it",
  "live.nl",
  "live.se",
  "live.ch",
  "live.be",
  "live.ca",
  "live.com.br",
  "live.com.mx",
  "live.com.ar",
  "live.co.jp",
  "live.com.cn",
  "live.co.in",
  "live.co.kr",
  "live.com.au",
  "live.co.nz",
  "live.co.za",
  "msn.com",
];

export const isHotmailEmail = (email) => {
  if (!email) return false;
  const checkEmail = email.toLowerCase();
  return HOTMAIL_DOMAINS.includes(checkEmail.split("@").at(-1));
};
