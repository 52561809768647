import * as React from "react";
import { cva } from "class-variance-authority";
import { useNavigate, createSearchParams } from "react-router-dom";

import { cn } from "lib/utils";

const buttonVariants = cva(
  "select-none flex items-center justify-center whitespace-nowrap rounded-full font-medium ring-offset-white transition-colors duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-gray-950 dark:focus-visible:ring-gray-300",
  {
    variants: {
      variant: {
        default:
          "bg-blue hover:bg-blue-600 text-white disabled:bg-blue-300 disabled:opacity-100",
        green: "bg-green hover:bg-green-600 text-white disabled:opacity-50",
        white: "bg-white text-black disabled:opacity-50",
        secondary: "bg-white text-black border border-black",
      },
      size: {
        default: "px-[24px] py-regular leading-7",
        small: "px-medium py-medium leading-4",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  (
    {
      onClick,
      reloadDocument = true,
      className,
      variant,
      size,
      href,
      search,
      queryParams,
      icon: Icon,
      children,
      tracking,
      ...props
    },
    ref
  ) => {
    const navigate = useNavigate();

    const handleClick = async (e) => {
      if (window.s && tracking) {
        const trackingMap = {
          "Start trade-in clicked": "event7",
          "Trade-in weight submitted": "event8",
          "Trade-in method chosen": "event9",
          "Trade-in Preparation checklist complete": "event10",
          "Trade-in Reward method chosen": "event11",
          "Trade-in signed up": "event12",
          "Trade-in confirmed": "event13",
        };
        const eventName = trackingMap[tracking];

        if (eventName) {
          window.s.events = eventName;
          window.s.linkTrackEvents = eventName;
          window.s.linkTrackVars = "events";
          window.s.tl(null, "o", tracking);
        }
      }

      if (onClick) {
        const result = await onClick(e);
        if (result === false || e.defaultPrevented) {
          return;
        }
      }
      if (href) {
        const navigateOptions = {
          pathname: href,
          replace: reloadDocument ? true : false,
          ...(search ? { search } : {}),
        };
        if (queryParams) {
          navigateOptions.search = createSearchParams(queryParams).toString();
        }
        navigate(navigateOptions);
      }
    };

    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        onClick={handleClick}
      >
        <span className="hover:underline">{children}</span>
        {Icon && (
          <span className="material-symbols-outlined align-bottom ml-x-small text-secondary max-w-[16px]">
            {Icon}
          </span>
        )}
      </button>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
